<template>
    <div class="report">
      <NavHeader  @reportchangedata="reportHandler"></NavHeader>
      <div :class="lang">
        <div class="report-list">
          <div class=" pubCon"  >
            <div class="report-title">
              <h3 v-if="this.$i18n.locale =='zh'">气候行动报告</h3>
              <h3 v-else>Climate Action Report</h3>
            </div>
            <ul class="report-ul" >
            <a :href="item.resourceList[0].url" target="_blank" v-for="(item,index) in zh_data.List" :key="index">
              <li>
                <span class="report-li-title">{{ item.title }}</span>
                <img :src="item.bannerList[0].url" alt="">
                <span>{{ item.date }}</span>
              </li>
            </a>
          </ul>
            <!-- <ul class="report-ul" v-if="this.$i18n.locale =='zh'">
              <a href="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240422091236395.pdf" target="_blank">
                <li>
                  <span class="report-li-title">2023 气候行动报告</span>
                  <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240305175630198.png" alt="">
                  <span>2024年4月发布</span>
                </li>
              </a>
            </ul>
            <ul class="report-ul" v-else>
              <a href="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240422091328009.pdf" target="_blank">
                <li>
                  <span class="report-li-title">2023 Climate Action Report</span>
                  <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240305175713080.png" alt="">
                  <span>Released in Apr. 2024</span>
                </li>
              </a>
            </ul> -->
          </div>
        </div>
      </div>
   
    </div>
   </template>
   
   <script>
   import NavHeader from "../components/Header"
   import {mapState} from "vuex";
   import { getdataInfo } from "../api/home";
   export default {
     name: "esgreport",
     data(){
       return{
         id: 9,
         pageType: 'climate',
         zh_data:{},
       }
     },
     mounted() {
       this.getList()
     },
     components:{
       NavHeader
     },
     computed:{
       ...mapState({
         lang:state =>state.lang
       })
     },
   
   methods:{
    async getList(){
       const data = await getdataInfo({ id: this.id, pageType: this.pageType })
       const zh_envdata= JSON.parse(data.esgContent.cnContent)
       const en_envdata= JSON.parse(data.esgContent.enContent)
       if(this.$i18n.locale=='zh'){
           this.zh_data = zh_envdata
         }else {
           this.zh_data = en_envdata
         }
     },
     reportHandler(){
       this.getList()
     }
   }
   
   }
   </script>
   
   <style scoped lang="scss">
   .report{
     .en .report-list{
       h3{
         font-size: 40px;
         font-family: "Barlow-Regular";
       }
       a{
         li{
           span{
             font-family: "Barlow-Regular";
           }
         }
   
   
       }
   
     }
     .report-list{
       background: #F5F5F7;
       .pubCon {
         width: 1200px;
         margin: 0 auto;
       }
       .report-title{
         padding-top: 80px;
         padding-bottom: 40px;
         h3{
          color: #22c3ad;
          text-align: left;
           font-weight: 700;
           font-size: 50px;
           line-height: 77px;
           letter-spacing: 1px;
        }
       }
       .report-ul{
         display: flex;
         width: 100%;
         padding-bottom: 80px;
         a{
           width: 25%;
           color: #353535;
          
           li{
            width: 100%;
             display: flex;
             flex-direction: column;
             justify-content: space-around;
             font-size: 16px;
        
             .report-li-title{
               font-style: normal;
               font-weight: 500;
               font-size: 20px;
               line-height: 28px;
               /* identical to box height */
               letter-spacing: 1px;
   
               color: #5EC0AE;
             }
             img{
               display: block;
               margin: 15px 0;
               width: 90%;
               height:auto;
               background-color: #fff;
               box-shadow: 0px 10px 20px rgba(0, 0, 0, .1);
             }
             span{
               display: inline-block;
               //font-family: 'PingFang SC';
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 22px;
               letter-spacing: 1px;
               color: #B8B8B8;
             }
           }
         }
   
       }
     }
   
   }
   @media (max-width: 680px) {
     .report{
       .report-list{
         background: #F5F5F7;
         .pubCon {
           width:100%;
           margin: 0 auto;
         }
         .report-title{
           width: 80%;
           margin: 0 auto;
           padding-top: 30px;
           padding-bottom: 30px;
           h3{
             color: #22c3ad;
             font-weight: bold;
             text-align: left;
             font-weight: 700;
             font-size: 38px;
             line-height: 1;
             letter-spacing: 1px;
           }
         }
         .report-ul{
           display: block;
           width: 100%;
           justify-content: space-between;
           padding-bottom: 80px;
           a{
             width: 80%;
             color: #353535;
             display: block;
             margin: 40px auto 0;
             li{
               width: 100%;
               display: flex;
               flex-direction: column;
               justify-content: space-around;
               font-size: 16px;
               .report-li-title{
                 font-style: normal;
                 font-weight: 500;
                 font-size: 20px;
                 line-height: 28px;
                 /* identical to box height */
                 letter-spacing: 1px;
   
                 color: #5EC0AE;
               }
               img{
                 display: block;
                 margin: 15px 0;
                 width: 100%;
                 height: auto;
                 //box-shadow: 0px 0px 8px rgba(0, 0, 0, .3);
               }
               span{
                 display: inline-block;
                 font-style: normal;
                 font-weight: 400;
                 font-size: 16px;
                 line-height: 22px;
                 letter-spacing: 1px;
                 color: #B8B8B8;
               }
             }
           }
   
         }
       }
   
     }
   
   }
   </style>
   